import cx from 'classnames';
import Link from 'next/link';

import { ArrowRightIcon } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import { triggerAccountSettingsLinkClickedEvent } from 'Services/analytics/events';
import type { SessionReplayMaskAttributes } from 'Services/datadog/datadog.types';
import { applyCypressData } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import ROUTES from 'Utilities/routes';

import styles from './UserMenuHeader.module.scss';

interface UserMenuAccountSettingsLinkProps {
	dealerName: string;
	isAccountSettingsActive: boolean;
	name: string;
	privacyParams: {
		dealershipName: SessionReplayMaskAttributes;
		usersName: SessionReplayMaskAttributes;
	};
}

export const UserMenuHeader: React.FC<UserMenuAccountSettingsLinkProps> = ({
	dealerName,
	isAccountSettingsActive,
	name,
	privacyParams,
}) => {
	const { dealershipName, usersName } = privacyParams;
	const isAccountSettingsEnabled = useFeatureToggle(FEATURES.accountSettings);

	return isAccountSettingsEnabled ? (
		<Link
			aria-current={isAccountSettingsActive}
			as={ROUTES.ACCOUNT_SETTINGS.as()}
			href={ROUTES.ACCOUNT_SETTINGS.href}
			legacyBehavior={false}
			{...applyCypressData(cypressIds.containers.accountMenuHeader)}
			className={cx(styles.navContainer, styles.headerContainer, styles.accountSettingsLink)}
			onClick={triggerAccountSettingsLinkClickedEvent}
		>
			<div className={styles.accountSettingsLinkName}>
				<span aria-current={isAccountSettingsActive && 'location'}>
					<h4 {...usersName}>{name}</h4>
				</span>
				{dealerName && (
					<p data-testid="dealership-name" {...dealershipName}>
						{dealerName}
					</p>
				)}
			</div>
			<ArrowRightIcon aria-hidden="true" size="small" />
		</Link>
	) : (
		<div
			{...applyCypressData(cypressIds.containers.accountMenuHeader)}
			className={cx(styles.navContainer, styles.headerContainer)}
		>
			<h4 {...privacyParams.usersName}>{name}</h4>
			{dealerName && (
				<p data-testid="dealership-name" {...privacyParams.dealershipName}>
					{dealerName}
				</p>
			)}
		</div>
	);
};
