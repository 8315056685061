import React from 'react';
import cx from 'classnames';
import Link from 'next/link';

import { Badge } from '@motorway/mw-highway-code';

import Separator from 'Storybook/core/Separator/Separator';
import NavAccordionContentList from 'Storybook/local/NavAccordion/components/NavAccordionContentList/NavAccordionContentList';
import { AccordionItem, AccordionRoot, AccordionTrigger } from 'Storybook/peripheral/Accordion';
import { applyCypressData } from 'Utilities/helpers';

import { isNavAccordionLink, isNavAccordionList } from './NavAccordion.helpers';
import type { NavAccordionItem, NavAccordionRouter } from './NavAccordion.types';

import styles from './NavAccordion.module.scss';

export type NavAccordionProps = {
	lists: NavAccordionItem[][];
	router: NavAccordionRouter;
};

const NavAccordion: React.FC<NavAccordionProps> = ({ lists, router }) => {
	const defaultActive = lists.flat().find(({ getActiveState }) => getActiveState(router));

	return (
		<AccordionRoot collapsible defaultValue={defaultActive?.id} type="single">
			{lists.map((list: NavAccordionItem[], idx: number) => {
				const items = list.map((item) => {
					const { cypressId, getActiveState, hide, icon: Icon, id, name, onClick } = item;
					const isVisible = !hide;

					const isList = isNavAccordionList(item);
					const isLink = isNavAccordionLink(item);
					const isAccordionActive = getActiveState(router);

					return (
						isVisible && (
							<AccordionItem key={id} value={id}>
								{isLink ? (
									<Link passHref as={item.as} href={item.href}>
										<a
											{...applyCypressData(cypressId)}
											aria-current={isAccordionActive}
											className={cx(styles.item, styles.link)}
											onClick={onClick}
										>
											{Icon && <Icon aria-hidden="true" size="small" />}
											<span>{name}</span>
											{typeof item.badgeLabel !== 'undefined' && (
												<div className={styles.badgeWrapper}>
													<Badge text={item.badgeLabel} />
												</div>
											)}
										</a>
									</Link>
								) : (
									<AccordionTrigger {...applyCypressData(cypressId)} onClick={onClick}>
										<div className={styles.item}>
											{Icon && <Icon aria-hidden="true" size="small" />}
											<span aria-current={isAccordionActive && 'location'}>{name}</span>
										</div>
									</AccordionTrigger>
								)}
								{isList && <NavAccordionContentList router={router} subItems={item.subItems} />}
							</AccordionItem>
						)
					);
				});

				if (idx !== lists.length - 1) {
					items.push(
						<div key={`separator-${idx}`} className={styles.separatorContainer}>
							<Separator width="large" />
						</div>,
					);
				}

				return items;
			})}
		</AccordionRoot>
	);
};

export default NavAccordion;
