import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';
import type { SnowplowEventPayload } from 'Services/analytics/analytics.types';

const { OTP } = ANALYTICS_CATEGORIES.PAYMENTS;
const { SNOWPLOW } = ANALYTICS_PROVIDERS;
export const CTA_CLICKED_SCHEMA = SNOWPLOW_EVENTS.CTA_CLICKED.SCHEMA;
const CTA_CLICKED_VERSION = SNOWPLOW_EVENTS.CTA_CLICKED.VERSION;
export const CLICK_SCHEMA = SNOWPLOW_EVENTS.CLICK.SCHEMA;
const CLICK_VERSION = SNOWPLOW_EVENTS.CLICK.VERSION;
export const POPUP_APPEAR_SCHEMA = SNOWPLOW_EVENTS.POPUP_APPEAR.SCHEMA;
const POPUP_APPEAR_VERSION = SNOWPLOW_EVENTS.POPUP_APPEAR.VERSION;

const versionFromSchemaName = {
	[CLICK_SCHEMA]: {
		version: CLICK_VERSION,
	},
	[CTA_CLICKED_SCHEMA]: {
		version: CTA_CLICKED_VERSION,
	},
	[POPUP_APPEAR_SCHEMA]: {
		version: POPUP_APPEAR_VERSION,
	},
};

type OtpAnalyticsEventCustomDataCtaClicked = { button_label: string; label: string; url: string } | { label: string };

export type OtpAnalyticsEvent = {
	customData?: {} | OtpAnalyticsEventCustomDataCtaClicked;
	name: string;
	schema: typeof CTA_CLICKED_SCHEMA | typeof CLICK_SCHEMA | typeof POPUP_APPEAR_SCHEMA;
};

export const createOTPSnowplowEvents = (
	events: (OtpAnalyticsEvent & {
		version: (typeof SNOWPLOW_EVENTS)[keyof typeof SNOWPLOW_EVENTS]['VERSION'];
	})[],
): {
	eventPayload: SnowplowEventPayload;
	provider: typeof SNOWPLOW;
}[] =>
	events.map((evt) => {
		return {
			eventPayload: getSnowplowEventPayload({
				category: OTP,
				customData: evt.customData,
				name: evt.name,
				schemaName: evt?.schema,
				version: evt?.version,
			}),
			provider: SNOWPLOW,
		};
	});

export const triggerOtpAnalyticsEvent = (event: OtpAnalyticsEvent): void => {
	if (!event) {
		return;
	}

	const { version } = versionFromSchemaName[event.schema as keyof typeof versionFromSchemaName];
	const { name } = event;
	const customData = event.customData ?? {};
	const { schema } = event;
	const transformedEvent = {
		customData,
		name,
		schema,
		version,
	};

	logAnalyticsEvents([...createOTPSnowplowEvents([transformedEvent])]);
};
