import type { ReactElement } from 'react';
import React from 'react';
import type { NavigationMenuListProps } from '@radix-ui/react-navigation-menu';
import { NavigationMenuList } from '@radix-ui/react-navigation-menu';

import styles from './MenuList.module.scss';

const MenuList = ({ children, ...props }: NavigationMenuListProps): ReactElement => (
	<NavigationMenuList className={styles.menuList} {...props}>
		{children}
	</NavigationMenuList>
);

export default MenuList;
