import { useFilters } from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { useListType } from 'Stores/ListStore/ListStore';
import type { ListType } from 'Types/listType';
import isCorrectVehicleListFetchingState from 'Utilities/helpers/isCorrectVehicleListFetchingState';
import useSaleTimes from 'Utilities/hooks/useSaleTimes';

export interface UseFiltersInterface {
	includeSold: boolean;
	includeUnderOffer: boolean;
}

// Buying Format will no longer be nullable once next sale feature toggle is removed.
const useShouldFetchVehicles = (initialListType: Nullable<ListType> = null): boolean => {
	const { isSaleActive } = useSaleTimes();
	const { includeSold, includeUnderOffer } = useFilters() as UseFiltersInterface;
	const listType = useListType();

	const hasAlwaysFetchFilter = includeSold || includeUnderOffer;

	return isCorrectVehicleListFetchingState({
		hasAlwaysFetchFilter,
		isSaleActive,
		listType: listType || initialListType,
	});
};

export default useShouldFetchVehicles;
