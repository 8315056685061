import { getErrorInfoboxId } from 'Components/shared/ErrorInfobox/ErrorInfobox.helpers';
import type { PaymentProviderKey } from 'Services/motorway/services/paymentOptions/paymentOptions.types';
import { HOUR, SECOND } from 'Utilities/consts';

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const GA_PAYMENT_CATEGORY = 'Vehicle payment';
export const GA_PAYMENT_WALLET_TRANSACTIONS = 'Payment wallet transactions';
export const GA_PAYMENT_TRANSACTION_STATEMENT = 'Transaction statement';

export const SNOWPLOW_EVENT_LABEL = {
	UNDISCLOSED_DAMAGE_ACTION: {
		/* they are ordered based on the ids */
		1: 'undisclosed_damage_button_proceed',
		2: 'undisclosed_damage_button_amend',
		3: 'undisclosed_damage_button_cancel',
	},
	UNDISCLOSED_DAMAGE_ACTION_AMEND: {
		/* they are ordered based on the ids */
		1: 'undisclosed_not_agreed_with_seller',
		2: 'undisclosed_agreed_with_seller',
	},
} as const;

export const SNOWPLOW_URL = {
	/* they are ordered based on the ids */
	UNDISCLOSED_DAMAGE_URL: {
		1: '/make-payment',
		2: '/undisclosed-damage/inform-seller',
		3: '/cancel',
	},
	/* they are ordered based on the ids */
	UNDISCLOSED_DAMAGE_URL_AMEND: {
		1: '/undisclosed-damage/amend-price',
		2: '/undisclosed-damage/terms',
	},
} as const;

export const SNOWPLOW_EVENT_NAME = {
	UNDISCLOSED_DAMAGE_ACTION: {
		/* they are ordered based on the ids */
		1: 'Proceed option clicked',
		2: 'Amend price option clicked',
		3: 'Cancel purchase option clicked',
	},
	UNDISCLOSED_DAMAGE_ACTION_AMEND: {
		/* they are ordered based on the ids */
		1: 'Not agreed with seller option clicked',
		2: 'Agreed with seller option clicked',
	},
} as const;

export const GA_OTP_LABEL = {
	INCORRECT_PASSWORD: 'otp_incorrect_password',
	REQUEST_NEW_OTP: 'otp_new_request',
	OTP_SUCCESSFUL: 'otp_successful',
	OTP_SETUP_SHOWN: 'otp_setup_shown',
	OTP_SETUP_CLOSED: 'otp_setup_closed',
	OTP_SETUP_BUTTON_CLICKED: 'otp_setup_button_clicked',
	OTP_PHONE_NUMBER_CLICKED: 'otp_phone_number_clicked',
} as const;

export const OTP_ACTION = {
	INCORRECT_PASSWORD_ATTEMPT_NUMBER: 'otp incorrect password attempt number',
	REQUEST_NEW_OTP: 'otp new request',
	OTP_SUCCESSFUL: 'otp successful',
	OTP_SETUP_SHOWN: 'otp set up shown',
	OTP_SETUP_CLOSED: 'otp set up closed',
	OTP_SETUP_BUTTON_CLICKED: 'otp set up button clicked',
	OTP_PHONE_NUMBER_CLICKED: 'otp phone number clicked',
} as const;

export const GA_PAYMENT_TRANSACTION_STATEMENT_NAME = {
	BUTTON_CLICKED: 'Transaction statement button clicked',
	BUTTON_CLICKED_OVERLAY: 'Transaction statement date overlay opened',
	DATE_RANGE_SELECT_BUTTON_CLICKED: 'Transaction statement date range select button clicked',
	DATE_RANGE_CHIP_SELECTED: 'Transaction statement chip selected',
	BUTTON_DOWNLOAD_MODAL_CLOSE: 'Transaction statement download modal closed',
	BUTTON_DOWNLOAD_CLICKED: 'Transaction statement download button clicked',
	TRANSACTION_STATEMENT_FILTER_SELECTED: 'Payment wallet transactions date filter selected',
	TRANSACTION_STATEMENT_DOWNLOADED: 'Transaction statement downloaded',
	TRANSACTION_STATEMENT_DATE_RANGE_CLEAR: 'Transaction statement date range cleared',
} as const;

export const PAYMENT_WIZARD_JOURNEYS = {
	ENTRY: 'entry',
	AMBER: 'amber',
	APPROVED: 'approved',
	GREEN: 'green',
	RED: 'red',
	INSUFFICIENT_FUNDS: 'insufficient_funds',
	PAYMENT_OPTIONS: 'payment_options',
	ERROR: 'error',
} as const;

export const VEHICLE_PAYMENT_BACK_BUTTON_TYPE = {
	PAYMENT: 'payment',
	PURCHASE: 'purchase',
} as const;

export const VEHICLE_PAYMENT_POST_SALE_OFFER_TYPE = {
	CLOSING_BID: 'closing_bid',
	COUNTER_OFFER: 'counter_offer',
} as const;

export const PAYMENT_STATUS_TYPE = {
	DONE: 'done',
	ERRORED: 'errored',
	FAILED: 'failed',
	INITIAL: 'initial',
	PENDING: 'pending',
} as const;

export const VEHICLE_PAYMENT_LONG_POOLING_DELAY_TIME = 3 * SECOND;
export const VEHICLE_PAYMENT_LONG_POOLING_EXPIRED_TIME = 2 * HOUR;
export const VEHICLE_PAYMENT_LONG_POOLING_MAX_RETRIES = 5;
export const CHECKBOX_ON_CHANGE_DELAY = 250;

export const INSUFFICIENT_FUNDS_ERROR_CODE = 'motorway.payments.insufficient_funds';

export const VEHICLE_PAYMENT_ERROR_INFOBOX_ID = `${getErrorInfoboxId()}`;

export const PAYMENT_OPTION_URL_SLUG_BY_KEY: Map<PaymentProviderKey, string> = new Map([
	['wallet-modulr', '/wallet/modulr-wallet'],
	['stock-funding-nextgear', '/stock-funding/next-gear'],
]);

export const PAYMENT_OPTION_KEY_BY_URL_SLUG: Map<string, PaymentProviderKey> = new Map(
	Array.from(PAYMENT_OPTION_URL_SLUG_BY_KEY.entries()).map(([key, value]) => [value, key]),
);
