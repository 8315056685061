import type { ParsedUrlQuery } from 'querystring';

import type { BaseThemeVariants } from 'Storybook/config/types';
import { Variant } from 'Types/variant';
import ROUTES from 'Utilities/routes';

type Props = {
	pathname: string;
	query: ParsedUrlQuery;
};

export const marketplaceActiveStateCallback = ({ pathname }: Props): boolean => pathname === ROUTES.VEHICLE_LIST.href;
export const tradeActiveStateCallback = ({ pathname }: Props): boolean => pathname === ROUTES.TRADE.href;
export const bidsActiveStateCallback = ({ pathname, query }: Props): boolean =>
	pathname === ROUTES.ACCOUNT.href && query?.category === 'bids';
export const purchasesActiveStateCallback = ({ pathname, query }: Props): boolean =>
	pathname === ROUTES.ACCOUNT.href && query?.category === 'purchases';
export const productsActiveStateCallback = ({ pathname }: Props): boolean =>
	([ROUTES.MOTORWAY_MOVE.href, ROUTES.MOTORWAY_PAY.href, ROUTES.MOTORWAY_TRADE.href] as string[]).includes(pathname);
export const variants: BaseThemeVariants[] = [Variant.PRIMARY, Variant.SECONDARY];
export const insightsActiveStateCallback = ({ pathname }: Props): boolean =>
	pathname === ROUTES.INSIGHTS_LANDING.href || pathname === ROUTES.INSIGHTS_RESULTS.href;
