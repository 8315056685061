import type { AxiosError } from 'axios';
import axios from 'axios';
import isObject from 'lodash/isObject';

function isAxiosErrorLike<ErrorResponse = unknown>(payload: unknown): payload is AxiosError<ErrorResponse> {
	return isObject(payload) && 'response' in payload && 'name' in payload && payload.name === 'AxiosError';
}

/**
 * Wrapper on original `axios.isAxiosError` function so we can pass ErrorResponse to it.
 */
export function isAxiosError<ErrorResponse = unknown>(payload: unknown): payload is AxiosError<ErrorResponse> {
	return axios.isAxiosError(payload) || isAxiosErrorLike<ErrorResponse>(payload);
}
