import type { ReactNode } from 'react';
import React, { useRef, useState } from 'react';
import type { Argument } from 'classnames';
import cx from 'classnames';
import { Button } from '@THC/components/Button/Button';
import { MDText } from 'i18n-react';
import { useRouter } from 'next/router';

import type { InputAuthProps } from '@motorway/mw-highway-code';
import { IconText, InputAuth } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';

import {
	AuthenticationFeedbackWithOtp,
	AuthenticationFeedbackWithoutOtp,
} from 'Components/AuthenticationFeedback/AuthenticationFeedback';
import cypressIds from 'Components/cypress_ids.json';
import { SNOWPLOW_BACKEND_SERVICES } from 'Services/analytics/analytics.const';
import { otpEventsBackendTrigger } from 'Services/analytics/events/paymentEvents/otpEvents';
import {
	CLICK_SCHEMA,
	CTA_CLICKED_SCHEMA,
	triggerOtpAnalyticsEvent,
} from 'Services/analytics/events/paymentEvents/otpSnowplowEvents';
import { applySessionReplayMask } from 'Services/datadog/datadogRum';
import { MASKED_EVENT_VALUES } from 'Services/datadog/datadogRum.const';
import { applyCypressData } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import { kycToShowOTPModal, useHandleKycStatus } from 'Utilities/hooks/useHandleKycStatus';
import { useStoreOtpPhoneValidated } from 'Utilities/hooks/useStoreOtpPhoneValidated';
import useUser from 'Utilities/hooks/useUser';
import ROUTES from 'Utilities/routes';
import type { KycStatus } from 'Utilities/types/kyc.types';

import { GA_OTP_LABEL, OTP_ACTION } from '../../VehiclePayment.const';
import { PHONES } from '../../VehiclePayment.helpers';
import Texts from '../../VehiclePayment.texts.json';

import { getSubDescState } from './OTPContent.helper';
import { type OTPContentProps, type SubDesc, OtpAction } from './OTPContent.types';
import { useOTP } from './useOTP';

import styles from './OTPContent.module.scss';

const OTP_MUST_BE_VALIDATED_PAGES = new Set<string>([ROUTES.VEHICLE_PAYMENT.href, ROUTES.WITHDRAW_FUNDS.href]);

const LocalT = new MDText(Texts.OTPModal);

const { OTP } = MASKED_EVENT_VALUES;
const privacyParams = { description: applySessionReplayMask({ maskedActionName: OTP.description }) };

// auxiliary components
export const AuthenticationFeedbackOtpDisabled: React.FC<{
	ctaNoNumber: string;
	ctaNoNumberSupport: ReactNode;
	extraStyles: Record<string, Argument>;
	title: ReactNode;
}> = ({ ctaNoNumber, ctaNoNumberSupport, extraStyles, title }) => {
	return (
		<div className={cx(styles.paymentsOTPContent, extraStyles.paymentsOTPContent)}>
			<div>
				<h1 className={cx(styles.title, extraStyles.title)}>{title}</h1>
				<div className={styles.desc}>
					<p>{ctaNoNumber}</p>
					<p>{ctaNoNumberSupport}</p>
				</div>
			</div>
		</div>
	);
};

export const InfoHeader: React.FC<{
	description: ReactNode;
	extraStyles: Record<string, Argument>;
	title: ReactNode;
}> = ({ description, extraStyles, title }) => {
	return (
		<>
			<h1 {...applyCypressData(cypressIds.payments.otp.otpTitle)} className={cx(styles.title, extraStyles.title)}>
				{title}
			</h1>
			<div className={styles.desc} {...privacyParams.description}>
				<p {...applyCypressData(cypressIds.payments.otp.otpDescription)}>{description}</p>
			</div>
		</>
	);
};

export const FeedbackHeader: React.FC<{ subDesc?: SubDesc }> = ({ subDesc }) => {
	return (
		<div className={cx(styles.subDesc, styles[subDesc?.type as string])} id={subDesc?.id}>
			{subDesc && <IconText icon={subDesc.icon} text={subDesc.text} variant="xsmall--small" />}
		</div>
	);
};

const OTPContent = ({
	bottomDesc,
	ctaNoNumber = `${LocalT.translate('CTANoNumber')}`,
	ctaNoNumberSupport = LocalT.translate('CTANoNumberSupport', {
		number: <Hyperlink href={`tel:${PHONES.PAYMENT_ERROR.replace(/ /g, '')}`} label={PHONES.PAYMENT_ERROR} />,
	}),
	ctaResend = `${LocalT.translate('CTAResend')}`,
	ctaWait = `${LocalT.translate('CTAWait')}`,
	desc,
	enquiryId = undefined,
	extraStyles = { paymentsOTPContent: undefined, title: undefined },
	logs,
	request,
	title = `${LocalT.translate('title')}`,
}: OTPContentProps): React.ReactElement => {
	const enquiryIdForLogs = logs.source === 'createPostSaleOffer' ? enquiryId : undefined;

	const [status, setStatus] = useState<'init' | 'setupOtp' | 'error' | 'success'>('init');
	const [subDesc, setSubDesc] = useState<SubDesc | undefined>(undefined);
	const [isStoreOtpPhoneValidated] = useStoreOtpPhoneValidated(false);
	const { kycStatus } = useHandleKycStatus({ source: 'setup' });
	const kycStatusAllowSetup = kycStatus === undefined ? true : kycToShowOTPModal.has(kycStatus as KycStatus);

	const { otpPhone, phoneConfirmedAt } = useUser();
	const { asPath, pathname } = useRouter();

	const isOTPSetupRequired = OTP_MUST_BE_VALIDATED_PAGES.has(pathname);
	const isPaymentsOTPFeatureEnabled = useFeatureToggle(FEATURES.paymentsOTP);
	const errorAttempt = useRef<number>(0);

	const hasPhoneMissing = !otpPhone || phoneConfirmedAt === null;
	const isOTPDisabled = hasPhoneMissing && !isStoreOtpPhoneValidated;
	const showAuthenticationFeedbackWithOtp =
		((phoneConfirmedAt === null && status === 'init') || status === 'error') && !isStoreOtpPhoneValidated;

	const { getCode, readableCountDown, resendButtonAvailable } = useOTP({
		disabled: isOTPDisabled && status !== 'setupOtp',
		enquiryId: enquiryIdForLogs,
		otpAction: status === 'setupOtp' ? OtpAction.VERIFY_PHONE : undefined,
		source: logs.source,
	});

	// If we are in a page that requires OTP validation and the phone is not validated, show the disabled screen
	if (isOTPSetupRequired && isOTPDisabled) {
		return (
			<AuthenticationFeedbackOtpDisabled
				ctaNoNumber={ctaNoNumber}
				ctaNoNumberSupport={ctaNoNumberSupport}
				extraStyles={extraStyles}
				title={title}
			/>
		);
	}

	// If paymentsOTP feature is not enabled, retain the old behavior
	if (!isPaymentsOTPFeatureEnabled) {
		if (isOTPDisabled) {
			return (
				<AuthenticationFeedbackOtpDisabled
					ctaNoNumber={ctaNoNumber}
					ctaNoNumberSupport={ctaNoNumberSupport}
					extraStyles={extraStyles}
					title={title}
				/>
			);
		}
	} else {
		// If OTP phone is not defined for the dealer, display the banner to contact support
		if (!otpPhone || !kycStatusAllowSetup) {
			return <AuthenticationFeedbackWithoutOtp />;
		}

		// If phone is not confirmed or there is an error in OTP status display the setup screen
		if (showAuthenticationFeedbackWithOtp) {
			// If phone is not confirmed or there is an error in OTP status

			return (
				<AuthenticationFeedbackWithOtp
					authenticationPhoneNumber={otpPhone}
					onClick={() => {
						triggerOtpAnalyticsEvent({
							customData: {
								button_label: 'Set up authentication number',
								label: GA_OTP_LABEL.OTP_SETUP_BUTTON_CLICKED,
								url: asPath,
							},
							name: OTP_ACTION.OTP_SETUP_BUTTON_CLICKED,
							schema: CTA_CLICKED_SCHEMA,
						});
						setStatus('setupOtp');
					}}
					onPhoneClick={() => {
						triggerOtpAnalyticsEvent({
							customData: { label: GA_OTP_LABEL.OTP_PHONE_NUMBER_CLICKED },
							name: OTP_ACTION.OTP_PHONE_NUMBER_CLICKED,
							schema: CLICK_SCHEMA,
						});
					}}
					showWarning={status === 'error'}
				/>
			);
		}
	}

	const getDescription = () => {
		if (desc) return desc;

		if (otpPhone) {
			const endOfPhoneNo = otpPhone.slice(otpPhone.length - 3, otpPhone.length);
			return `${LocalT.translate('desc', { number: endOfPhoneNo })}`;
		}

		return null;
	};

	const handleResendClick = () => {
		getCode();

		otpEventsBackendTrigger({
			action: OTP_ACTION.REQUEST_NEW_OTP,
			backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_NEW_REQUEST,
			label: GA_OTP_LABEL.REQUEST_NEW_OTP,
		});
	};

	const handleFill: InputAuthProps['onFill'] = ({ onError, onSuccess, value }) => {
		request(value)
			.then((cb) => {
				errorAttempt.current = 0;

				setSubDesc(getSubDescState(`${LocalT.translate('subDesc.success')}`, 'success'));
				onSuccess(cb);
				setStatus('success');

				otpEventsBackendTrigger({
					action: OTP_ACTION.OTP_SUCCESSFUL,
					backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_SUCCESSFUL,
					label: GA_OTP_LABEL.OTP_SUCCESSFUL,
				});
			})
			.catch((customError) => {
				errorAttempt.current += 1;

				otpEventsBackendTrigger({
					action: `${OTP_ACTION.INCORRECT_PASSWORD_ATTEMPT_NUMBER} ${errorAttempt.current}`,
					backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_INCORRECT_PASSWORD_ATTEMPT_NUMBER,
					label: `${GA_OTP_LABEL.INCORRECT_PASSWORD}_${errorAttempt.current}`,
				});

				onError();

				if (!customError) {
					setSubDesc(getSubDescState(`${LocalT.translate('subDesc.notMach')}`, 'error'));
				} else if (customError.message === 'verification_limit_reached') {
					setSubDesc(getSubDescState(`${LocalT.translate('subDesc.codeSent')}`));
					otpEventsBackendTrigger({
						action: OTP_ACTION.REQUEST_NEW_OTP,
						backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_NEW_REQUEST,
						label: GA_OTP_LABEL.REQUEST_NEW_OTP,
					});
				} else if (customError.message === 'rate_limit_reached') {
					setSubDesc(getSubDescState(`${LocalT.translate('subDesc.rateLimit')}`, 'error'));
				} else if (isPaymentsOTPFeatureEnabled && isOTPDisabled) {
					setStatus('error');
				} else
					setSubDesc(
						getSubDescState(
							<span>
								{LocalT.translate('subDesc.unknown', {
									phone: (
										<Hyperlink
											inherit
											href={`tel:${PHONES.PAYMENT_ERROR.replace(/ /g, '')}`}
											label={PHONES.PAYMENT_ERROR}
										/>
									),
								})}
							</span>,
							'error',
						),
					);
			});
	};

	return (
		<div
			{...applyCypressData(cypressIds.payments.otp.otpModal)}
			className={cx(styles.paymentsOTPContent, extraStyles.paymentsOTPContent)}
		>
			<div>
				<InfoHeader description={getDescription()} extraStyles={extraStyles} title={title} />
				<div className={styles.authenticateBox}>
					<FeedbackHeader subDesc={subDesc} />
					<InputAuth
						{...applyCypressData(cypressIds.inputs.paymentOTP)}
						aria-describedby={subDesc ? subDesc.id : undefined}
						data-testid="input-otp"
						id="payment-otp"
						onFill={handleFill}
					/>
				</div>
			</div>

			<div {...applyCypressData(cypressIds.payments.button.resendButton)} className={styles.buttonWrapper}>
				<Button
					disabled={!resendButtonAvailable}
					label={resendButtonAvailable ? ctaResend : `${ctaWait} ${readableCountDown}`}
					onClick={handleResendClick}
					variant={resendButtonAvailable ? 'primary' : 'tertiary'}
				/>
			</div>
			{bottomDesc}
		</div>
	);
};

export default OTPContent;
