import { getSnowplowEventPayload, logAnalyticsEvent } from 'Services/analytics/analytics';

import type { BasePaymentEventProps } from './basePaymentEvent.type';

export function basePaymentsEvent<CustomData extends UnknownShapeObject>({
	category,
	customData,
	customGaData,
	name,
	schemaName,
	version,
}: BasePaymentEventProps<CustomData>): void {
	logAnalyticsEvent({
		eventPayload: getSnowplowEventPayload({
			category,
			customData,
			name,
			schemaName,
			version,
		}),
		provider: 'SNOWPLOW',
	});

	const { action: GaAction, isLegacyEvent, label: GaLabel } = customGaData ?? {};

	logAnalyticsEvent({
		eventPayload: {
			action: GaAction ?? name,
			category,
			isLegacyEvent: isLegacyEvent ?? false,
			label: GaLabel ?? (customData?.label as string) ?? '',
		},
		provider: 'GOOGLE_ANALYTICS',
	});
}
