import type { ReactElement } from 'react';
import React from 'react';

import cypressIds from 'Components/cypress_ids.json';
import { Content, MenuItem, Trigger } from 'Storybook/core/Menu';
import { productsActiveStateCallback } from 'Storybook/core/Menu/__stories__/helpers/helpers';
import { getProductNavMenuItems } from 'Storybook/core/Menu/Menu.helpers';
import type { ContentProps, NavMenuRouter } from 'Storybook/core/Menu/Menu.types';
import DropdownButton from 'Storybook/peripheral/DropdownButton/DropdownButton';
import { Variant } from 'Types/variant';
import { applyCypressData } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import type { GuestMenuAnalytics, UserMenuAnalytics } from '../../SiteNavigation.analytics';
import { MENU_ITEMS } from '../../SiteNavigation.consts';

type ProductMenuItemProps = Pick<ContentProps, 'side'> & {
	className?: string;
	router: NavMenuRouter;
	triggerGAEvent: (
		eventName: keyof typeof UserMenuAnalytics,
	) => void | ((eventName: keyof typeof GuestMenuAnalytics) => void);
};

const ProductMenuItem = ({ className, router, side, triggerGAEvent }: ProductMenuItemProps): ReactElement => {
	const isTransportExperience = useFeatureToggle(FEATURES.transportExperience);
	const isTradeExperience = useFeatureToggle(FEATURES.tradeExperience);

	const NAV_MENU_ITEMS_PRODUCTS = getProductNavMenuItems({ isTradeExperience, isTransportExperience, triggerGAEvent });

	return (
		<MenuItem className={className}>
			<Trigger asChild onClick={() => triggerGAEvent('products')}>
				<DropdownButton
					isActive={productsActiveStateCallback(router)}
					text={MENU_ITEMS.PRODUCTS.LABEL}
					variant={Variant.SECONDARY}
					{...applyCypressData(cypressIds.navAccordion.products.base)}
				/>
			</Trigger>
			<Content
				lists={NAV_MENU_ITEMS_PRODUCTS}
				router={router}
				side={side}
				size="large"
				variant={Variant.SECONDARY}
				{...applyCypressData(cypressIds.navAccordion.products.dropdown)}
			/>
		</MenuItem>
	);
};

export default ProductMenuItem;
