import type { FC } from 'react';
import { MDText } from 'i18n-react';
import Link from 'next/link';
import type { NextRouter } from 'next/router';

import { Button } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import { triggerSignInLinkClicked, triggerSignUpLinkClicked } from 'Services/analytics/events';
import NavAccordion from 'Storybook/local/NavAccordion/NavAccordion';
import { getLoggedOutNavAccordionItems } from 'Storybook/local/NavAccordion/NavAccordion.helpers';
import { Variant } from 'Types/variant';
import { applyCypressData } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import ROUTES from 'Utilities/routes';

import LocalTexts from './GuestMenu.texts';

import styles from './GuestMenu.module.scss';

const LocalT = new MDText(LocalTexts);

export type GuestMenuProps = {
	router: NextRouter;
};

const GuestMenuButtons = () => (
	<div className={styles.newGlobalExperienceUserButtonsContainer}>
		<Link passHref as={ROUTES.SIGN_IN.as()} href={ROUTES.SIGN_IN.href}>
			<Button
				{...applyCypressData(cypressIds.guestMenuLinks.signIn)}
				as="a"
				className={styles.actionButton}
				data-testid="signin_button"
				fullWidth={true}
				label={LocalT.translate('signInButtonLabel')}
				onClick={triggerSignInLinkClicked}
				variant={Variant.SECONDARY}
			/>
		</Link>
		<Link passHref as={ROUTES.SIGN_UP.as()} href={ROUTES.SIGN_UP.href}>
			<Button
				{...applyCypressData(cypressIds.guestMenuLinks.signUp)}
				as="a"
				data-testid="signup_button"
				fullWidth={true}
				label={LocalT.translate('signUpButtonLabel')}
				onClick={triggerSignUpLinkClicked}
				variant={Variant.PRIMARY}
			/>
		</Link>
	</div>
);

const GuestMenu: FC<GuestMenuProps> = ({ router }) => {
	const isTransportExperience = useFeatureToggle(FEATURES.transportExperience);
	const isTradeExperience = useFeatureToggle(FEATURES.tradeExperience);

	const NAV_ACCORDION_ITEMS_LOGGED_OUT = getLoggedOutNavAccordionItems({
		isTradeExperience,
		isTransportExperience,
	});

	const NAV_ACCORDION_LISTS = [NAV_ACCORDION_ITEMS_LOGGED_OUT];

	return (
		<div className={styles.container}>
			<NavAccordion lists={NAV_ACCORDION_LISTS} router={router} />

			<GuestMenuButtons />
		</div>
	);
};

export default GuestMenu;
