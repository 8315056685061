import type { ReactElement } from 'react';
import { MDText } from 'i18n-react';
import type { NextRouter } from 'next/router';

import type { IconComponentProps } from '@motorway/mw-highway-code';
import { SignOutIcon } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import { triggerNavigationSignOutClickedEvent } from 'Services/analytics/events';
import type { ExtensionContent } from 'Storybook/core/Menu/Menu.types';
import { triggerUserSiteNavigationGAEvent } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';

import LocalTexts from './UserMenu.texts';

const LocalT = new MDText(LocalTexts);

type MenuButtonProps = {
	as?: string;
	cypressId: string;
	extensionContent?: ExtensionContent;
	href?: string;
	icon: (props: IconComponentProps) => ReactElement;
	id: string;
	isActivePage?: (router: NextRouter) => boolean;
	onClick: () => void | Promise<void>;
	text: string;
};

type GetMobileNavFooterMenuArgs = {
	onSignOut?: () => void | Promise<void>;
};

export const getMobileNavFooterMenu = ({ onSignOut }: GetMobileNavFooterMenuArgs): MenuButtonProps[] => [
	{
		cypressId: cypressIds.buttons.signOut,
		icon: SignOutIcon,
		id: 'sign-out',
		onClick: () => {
			triggerUserSiteNavigationGAEvent('signOut');
			triggerNavigationSignOutClickedEvent();
			return onSignOut?.();
		},
		text: `${LocalT.translate('signout')}`,
	},
];
