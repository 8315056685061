import type { ParsedUrlQuery } from 'querystring';
import type { z } from 'zod';

import type { BasicPaginationMeta } from 'Components/Pagination/Pagination.types';
import ROUTES from 'Utilities/routes';

import type { PriceBreakdown } from '../VehiclePayment/VehiclePayment.types';

import type { paymentAccountScanSchema } from './Wallet.helpers';

export enum WALLET_SLUGS {
	COMPLETED = 'completed',
	// UPCOMMING = 'upcomming',
}

export enum TransactionsDateRange {
	ALL = 'ALL',
	CUSTOM = 'CUSTOM',
	LAST30 = 'LAST30',
	LAST7 = 'LAST7',
	TODAY = 'TODAY',
	YESTERDAY = 'YESTERDAY',
}

export type IncludeScan = Branded<'IncludeScan', true>;
export type ExcludeScan = Branded<'ExcludeScan', false>;

export type IncludeOrExcludeScan = IncludeScan | ExcludeScan;

export interface GetTransactionsApiRequestPayload {
	fromDate?: string;
	itemsPerPage?: number;
	keyword?: string;
	pageNumber?: number;
	toDate?: string;
}

export interface GetTransactionsParams {
	dateRange?: TransactionsDateRange | string;
	keyword?: string;
	pageNumber?: number;
}

export interface PureTransaction {
	amount: number;
	enquiryId: number;
	id: string;
	reference: string;
	transactionDate: string;
	vrm?: string;
}

export interface SingleTransactionExtended {
	amendmentAmount: number;
	initialOfferAmount: number;
	priceBreakdown: PriceBreakdown[];
	reference: string;
	transactionDate: string;
}

export type TransactionMeta = BasicPaginationMeta;

export interface WalletListRow {
	amount: number;
	date: string;
	enquiryId: number;
	ref: string;
	transactionId: string;
	vrm?: string;
}

export type WalletListRowProps = Pick<WalletListRow, 'amount' | 'enquiryId' | 'transactionId' | 'vrm'> & {
	reference: WalletListRow['ref'];
};

export interface WalletTableGroup {
	date: string;
	rows: WalletListRow[];
}

export interface UseWalletTransactionsReturn {
	data: WalletTableGroup[];
	firstTransactionDate: string | undefined;
	getTransactions: (params?: GetTransactionsParams) => void;
	hasError: HookWalletErrorType;
	meta: TransactionMeta;
	requestInProgress: boolean;
}

export type UseWalletTransactionProps = {
	initialData?: WalletTableGroup[];
	initialMeta?: TransactionMeta;
};

export type WalletPageProps = {
	initialPaymentAccount: PaymentAccounts;
	initialTransactionData?: WalletTableGroup[];
	initialTransactionMeta?: TransactionMeta;
};

export type WalletResultCounterProps = {
	number: number;
	requestInProgress: boolean;
};

export const defaultWalletUrl = ROUTES.DEALER_WALLET.as(WALLET_SLUGS.COMPLETED);

export type PaymentAccountsScan = z.infer<typeof paymentAccountScanSchema>;

export type PaymentAccounts<Scan extends IncludeOrExcludeScan = IncludeScan> = {
	balance: number | null;
	createdAt?: string;
	id: number;
} & (Scan extends IncludeScan ? { scan: PaymentAccountsScan } : {});

export interface GetPaymentAccountsApiRequestParams {
	include?: 'scan';
}

export interface CheckFundsApiRequestParams {
	amount: number;
	enquiryId: string | number;
}

export type CheckFundsApiResponse<S = 'success' | 'failure', T = boolean> = S extends 'failure'
	? {
			status: S;
	  }
	: T extends true
	? {
			hasSufficientFunds: T;
			status: S;
	  }
	: {
			balanceDifference: number;
			hasNilBalance: boolean;
			hasSufficientFunds: T;
			scan?: PaymentAccountsScan;
			status: S;
	  };

export interface CheckFundsAgainstBalanceParams {
	amount?: number;
	paymentAccounts: PaymentAccounts[];
}

export interface UseWalletPaymentAccountsProps {
	initialData: PaymentAccounts;
}

export interface UseWalletPaymentAccountsReturn {
	data: PaymentAccounts;
	getPaymentAccount: () => void;
	hasError: HookWalletErrorType;
	hasModulrAccountConnected: boolean | undefined;
	requestInProgress: boolean;
}

export interface WalletSearchProps {
	submitSearch: (data: WalletQueryParams) => void;
}

export interface WalletQueryParams extends ParsedUrlQuery {
	dateRange: TransactionsDateRange | string;
	keyword: string;
}

export const KEYWORD_MAX_LENGTH = 18;
export const KEYWORD_MIN_LENGTH = 3;

export type HookWalletErrorType = false | undefined | 'duringFirstLoad' | 'duringRefresh';

export interface WalletResetFilterProps {
	onReset: () => void;
}
