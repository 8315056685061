import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import type { Nullable } from 'Types/utils';

import type { LIST_POSITION_PAGES } from './VehicleListPositionsStore.consts';

// To update with import once https://github.com/motorway/motorway-dealer-webapp/pull/1719 is merged
const isDev = process.env.NODE_ENV === 'development';
export interface ListPosition {
	createdAt: number;
	listScrollPosition: number;
}

export type ListPositionPages = (typeof LIST_POSITION_PAGES)[keyof typeof LIST_POSITION_PAGES];

type Positions = Record<ListPositionPages, ListPosition>;

type GetTimeSinceListPositionCache = (page: ListPositionPages) => number;
type GetListPosition = (page: ListPositionPages) => ListPosition | undefined;
type ResetListPosition = Noop;
type SetListPosition = (listPosition: ListPosition, page: ListPositionPages) => void;

interface UseVehicleListPositionStore {
	getListPosition: GetListPosition;
	getTimeSinceListPositionCache: GetTimeSinceListPositionCache;
	listPosition: Nullable<Positions>;
	resetListPosition: ResetListPosition;
	setListPosition: SetListPosition;
}

const vehicleListPositionStore = devtools<UseVehicleListPositionStore>(
	(set, get) => ({
		getListPosition: (page: ListPositionPages) => {
			const { listPosition } = get();
			return listPosition?.[page];
		},
		getTimeSinceListPositionCache: (page: ListPositionPages) => {
			const { listPosition } = get();
			return listPosition?.[page]?.createdAt ? Date.now() - listPosition[page].createdAt : 0;
		},
		listPosition: null,
		resetListPosition: () => {
			set({ listPosition: null });
		},
		setListPosition: (listPosition: ListPosition, page: ListPositionPages) => {
			const setListPositionHandler = (state: UseVehicleListPositionStore) => ({
				listPosition: { ...state.listPosition, [page]: listPosition },
			});
			set(setListPositionHandler);
		},
	}),
	{ enabled: isDev, name: 'VehicleListPositionStore' },
);

const useVehicleListPositionStore = create(
	persist(vehicleListPositionStore, {
		name: 'vehicle-list-position-storage',
		storage: createJSONStorage(() => sessionStorage),
	}),
);

export const useGetTimeSinceListPositionCache = (): GetTimeSinceListPositionCache =>
	useVehicleListPositionStore((store) => store.getTimeSinceListPositionCache);

export const useGetListPosition = (): GetListPosition => useVehicleListPositionStore((store) => store.getListPosition);

export const useResetListPosition = (): ResetListPosition =>
	useVehicleListPositionStore((store) => store.resetListPosition);

export const useSetListPosition = (): SetListPosition => useVehicleListPositionStore((store) => store.setListPosition);

export default useVehicleListPositionStore;
