import { MDText } from 'i18n-react';

import { RedWarningTriangleIcon } from '@motorway/motorway-storybook-cra';
import { DamageTyreProblemIcon } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import {
	triggerNavigationActiveBidsClickedEvent,
	triggerNavigationCancelledPurchasesClickedEvent,
	triggerNavigationCompletedPurchasesClickedEvent,
	triggerNavigationDidntWinBidsClickedEvent,
	triggerNavigationUnderOfferBidsClickedEvent,
} from 'Services/analytics/events';
import { triggerUserSiteNavigationGAEvent } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import { AccountCategory, AccountSlugs } from 'Types/account';
import ROUTES from 'Utilities/routes';

import LocalTexts from './Menu.texts';
import type { LinkItemBaseProps } from './Menu.types';

const LocalT = new MDText(LocalTexts);

export const NAV_MENU_MOCK_ITEMS: LinkItemBaseProps[][] = [
	[
		{
			as: '/tyre',
			cypressId: 'cy-tyre',
			getActiveState: ({ pathname }) => pathname === '/tyre',
			href: '/tyre',
			icon: DamageTyreProblemIcon,
			id: 'tyre-problem',
			text: LocalT.translate('navMenuMockItemLabels.tyreProblem'),
		},
	],
	[
		{
			as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.ACTIVE),
			cypressId: 'cy-active',
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href &&
				query?.category === AccountCategory.BIDS &&
				query?.slug === AccountSlugs.ACTIVE,
			href: ROUTES.ACCOUNT.href,
			id: AccountSlugs.ACTIVE,
			text: LocalT.translate('navMenuMockItemLabels.active'),
		},
		{
			as: '/not-active',
			cypressId: 'cy-not-active',
			getActiveState: ({ pathname }) => pathname === '/not-active',
			href: '/not-active',
			id: 'not-active',
			text: LocalT.translate('navMenuMockItemLabels.notActive'),
		},
	],
	[
		{
			cypressId: 'cy-button',
			icon: RedWarningTriangleIcon,
			id: 'button',
			onClick: () => true,
			text: LocalT.translate('navMenuMockItemLabels.button'),
		},
	],
];

export const NAV_MENU_ITEMS_BIDS_AND_OFFERS: LinkItemBaseProps[][] = [
	[
		{
			as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.ACTIVE),
			cypressId: cypressIds.navAccordion.bidsAndOffers.active,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href &&
				query?.category === AccountCategory.BIDS &&
				query?.slug === AccountSlugs.ACTIVE,
			href: ROUTES.ACCOUNT.href,
			onClick: (): void => {
				triggerUserSiteNavigationGAEvent('bidsAndOffersActive');
				triggerNavigationActiveBidsClickedEvent();
			},
			text: LocalT.translate('navMenuItemsBidsAndOffers.active'),
		},
		{
			as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.UNDER_OFFER),
			cypressId: cypressIds.navAccordion.bidsAndOffers.underOffer,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href &&
				query?.category === AccountCategory.BIDS &&
				query?.slug === AccountSlugs.UNDER_OFFER,
			href: ROUTES.ACCOUNT.href,
			onClick: (): void => {
				triggerUserSiteNavigationGAEvent('bidsAndOffersUnderOffer');
				triggerNavigationUnderOfferBidsClickedEvent();
			},
			text: LocalT.translate('navMenuItemsBidsAndOffers.underOffer'),
		},
		{
			as: ROUTES.ACCOUNT.as(AccountCategory.BIDS, AccountSlugs.DIDNT_WIN),
			cypressId: cypressIds.navAccordion.bidsAndOffers.didntWin,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href &&
				query?.category === AccountCategory.BIDS &&
				query?.slug === AccountSlugs.DIDNT_WIN,
			href: ROUTES.ACCOUNT.href,
			onClick: (): void => {
				triggerUserSiteNavigationGAEvent('bidsAndOffersDidntWin');
				triggerNavigationDidntWinBidsClickedEvent();
			},
			text: LocalT.translate('navMenuItemsBidsAndOffers.didntWin'),
		},
	],
];

export const NAV_MENU_ITEMS_PURCHASES: LinkItemBaseProps[][] = [
	[
		{
			as: ROUTES.ACCOUNT.as(AccountCategory.PURCHASES, AccountSlugs.COMPLETE),
			cypressId: cypressIds.navAccordion.purchases.complete,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href &&
				query?.category === AccountCategory.PURCHASES &&
				query?.slug === AccountSlugs.COMPLETE,
			href: ROUTES.ACCOUNT.href,
			onClick: (): void => {
				triggerUserSiteNavigationGAEvent('purchasesComplete');
				return triggerNavigationCompletedPurchasesClickedEvent();
			},
			text: LocalT.translate('navMenuItemsPurchases.complete'),
		},
		{
			as: ROUTES.ACCOUNT.as(AccountCategory.PURCHASES, AccountSlugs.CANCELLED),
			cypressId: cypressIds.navAccordion.purchases.cancelled,
			getActiveState: ({ pathname, query }) =>
				pathname === ROUTES.ACCOUNT.href &&
				query?.category === AccountCategory.PURCHASES &&
				query?.slug === AccountSlugs.CANCELLED,
			href: ROUTES.ACCOUNT.href,
			onClick: (): void => {
				triggerUserSiteNavigationGAEvent('purchasesCancelled');
				triggerNavigationCancelledPurchasesClickedEvent();
			},
			text: LocalT.translate('navMenuItemsPurchases.cancelled'),
		},
	],
];

export const NAV_MENU_MOCK_ROUTER = { asPath: '/', pathname: '/', query: {} };

export const NAV_MENU_ACTIVE_MOCK_ROUTER = {
	asPath: ROUTES.ACCOUNT.href,
	pathname: ROUTES.ACCOUNT.href,
	query: { category: AccountCategory.BIDS, slug: AccountSlugs.ACTIVE },
};
