import React from 'react';
import cx from 'classnames';
import type { AccordionTriggerProps as RadixAccordionTriggerProps } from '@radix-ui/react-accordion';
import { Header, Trigger } from '@radix-ui/react-accordion';
import type { IconComponentProps } from '@THC/components/Icon/Icon.types';
import { ArrowDownIcon } from '@THC/components/Icons/Arrow';

import styles from './AccordionTrigger.module.scss';

type AccordionTriggerProps = RadixAccordionTriggerProps & {
	hideArrowIcon?: boolean;
	iconProps?: IconComponentProps;
};

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
	({ children, className, hideArrowIcon, iconProps, ...props }, forwardedRef) => (
		<Header className={styles.header}>
			<Trigger className={cx(styles.trigger, className)} {...props} ref={forwardedRef}>
				<>
					{children}
					{hideArrowIcon ? null : (
						<div className={styles.chevron}>
							<ArrowDownIcon size={iconProps?.size || 'small'} {...iconProps} />
						</div>
					)}
				</>
			</Trigger>
		</Header>
	),
);

export default AccordionTrigger;
