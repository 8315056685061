import { MDText } from 'i18n-react';

import type { IconComponentProps } from '@motorway/mw-highway-code';
import { HelpIcon, PaperIcon, PriceTagIcon, SignOutIcon, VehicleTagIcon, WalletIcon } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import {
	triggerNavigationBillingClickedEvent,
	triggerNavigationHelpCenterClickedEvent,
	triggerNavigationPricingClickedEvent,
	triggerNavigationSignOutClickedEvent,
	triggerNavigationTradeClickedEvent,
	triggerNavigationWalletClickedEvent,
} from 'Services/analytics/events';
import type { LinkItemBaseProps } from 'Storybook/core/Menu/Menu.types';
import { triggerUserSiteNavigationGAEvent } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import HELP_CENTER_URL from 'Utilities/helpers/getHelpCenterUrl';
import ROUTES from 'Utilities/routes';

import AccountMenuTexts from './AccountMenu.texts';

const LocalT = new MDText(AccountMenuTexts);

export const getAccountMenuLinks = ({
	isBillingEnabled,
	isTradeListingsEnabled,
	isWalletEnabled,
	onBillingClick,
	onSignOutClick,
	onTradeListingsClick,
	onWalletClick,
}: {
	isBillingEnabled: boolean;
	isTradeListingsEnabled: boolean;
	isWalletEnabled: boolean;
	onBillingClick: () => Promise<void>;
	onSignOutClick: () => void;
	onTradeListingsClick: () => void;
	onWalletClick: () => void;
}): LinkItemBaseProps[][] => {
	const accountMenuLinks: LinkItemBaseProps[] = [
		{
			cypressId: cypressIds.buttons.tradeListings,
			hide: !isTradeListingsEnabled,
			icon: (props: IconComponentProps) => <VehicleTagIcon {...props} />,
			id: 'trade listings',
			onClick: () => {
				triggerUserSiteNavigationGAEvent('trade');
				triggerNavigationTradeClickedEvent();
				return onTradeListingsClick();
			},
			text: `${LocalT.translate('tradeListings')}`,
		},
		{
			cypressId: cypressIds.buttons.wallet,
			hide: !isWalletEnabled,
			icon: WalletIcon,
			id: 'wallet',
			onClick: () => {
				triggerUserSiteNavigationGAEvent('wallet');
				triggerNavigationWalletClickedEvent();
				return onWalletClick();
			},
			text: `${LocalT.translate('wallet')}`,
		},
		{
			cypressId: cypressIds.buttons.billing,
			hide: !isBillingEnabled,
			icon: PaperIcon,
			id: 'billing',
			onClick: () => {
				triggerUserSiteNavigationGAEvent('billing');
				triggerNavigationBillingClickedEvent();
				return onBillingClick();
			},
			text: `${LocalT.translate('billing')}`,
		},
		{
			cypressId: cypressIds.buttons.helpCenter,
			icon: () => HelpIcon({ filled: false }),
			id: 'help-center',
			onClick: () => {
				window.open(HELP_CENTER_URL, '_blank');
				triggerNavigationHelpCenterClickedEvent();
				triggerUserSiteNavigationGAEvent('helpCenter');
			},
			text: `${LocalT.translate('helpCenter')}`,
		},
		{
			as: ROUTES.PRICING.as(),
			cypressId: cypressIds.buttons.pricing,
			getActiveState: ({ asPath }) => asPath === ROUTES.PRICING.href,
			href: ROUTES.PRICING.href,
			icon: PriceTagIcon,
			onClick: () => {
				triggerUserSiteNavigationGAEvent('pricing');
				triggerNavigationPricingClickedEvent();
			},
			text: LocalT.translate('pricing'),
		},
	];

	const footerActions: LinkItemBaseProps[] = [
		{
			cypressId: cypressIds.buttons.signOut,
			icon: SignOutIcon,
			id: 'sign-out',
			onClick: () => {
				triggerUserSiteNavigationGAEvent('signOut');
				triggerNavigationSignOutClickedEvent();
				return onSignOutClick();
			},
			text: `${LocalT.translate('signout')}`,
		},
	];

	return [accountMenuLinks, footerActions];
};
