import ROUTES from 'Utilities/routes';

import type { ButtonConfig, UnauthorizedFallback } from './useVehicleListButtonConfig.types';

export const FALLBACK_BUTTON_CONFIG: Record<UnauthorizedFallback, ButtonConfig> = {
	index: {
		as: ROUTES.INDEX.as(),
		href: ROUTES.INDEX.href,
		onClick: () => undefined,
		replace: false,
	},
	signIn: {
		as: ROUTES.SIGN_IN.as(),
		href: ROUTES.SIGN_IN.href,
		onClick: () => undefined,
		replace: false,
	},
	signUp: {
		as: ROUTES.SIGN_UP.as(),
		href: ROUTES.SIGN_UP.href,
		onClick: () => undefined,
		replace: false,
	},
};
