import type { HTMLProps, ReactElement, ReactNode, Ref } from 'react';
import React from 'react';
import cx from 'classnames';

import { ArrowDownIcon } from '@motorway/mw-highway-code';

import type { BaseThemeVariants } from 'Storybook/config/types';
import { Variant } from 'Types/variant';

import styles from './DropdownButton.module.scss';

export type DropdownButtonProps = HTMLProps<HTMLButtonElement> & {
	['data-state']?: string;
	isActive: boolean;
	ref?: Ref<HTMLButtonElement>;
	text: ReactNode;
	variant?: BaseThemeVariants;
};

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
	({ isActive, text, variant = Variant.PRIMARY, ...props }, forwardRef): ReactElement => (
		<button
			ref={forwardRef}
			className={cx(styles.dropdownButton, styles[variant], { [styles.isActive]: isActive })}
			{...props}
			type="button"
		>
			<span>{text}</span>
			<div className={styles.chevron}>
				<ArrowDownIcon aria-hidden="true" size="small" />
			</div>
		</button>
	),
);

export default DropdownButton;
