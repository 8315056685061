import { logger } from 'Services/logger/logger';
import type { ListType } from 'Types/listType';
import type { SaleTimes } from 'Types/saleTimes';

interface IsCorrectVehicleListFetchingStateParams {
	hasAlwaysFetchFilter: boolean;
	isSaleActive?: SaleTimes['isSaleActive'];
	listType: ListType;
}

const isCorrectVehicleListFetchingState = ({
	hasAlwaysFetchFilter,
	isSaleActive,
	listType,
}: IsCorrectVehicleListFetchingStateParams): boolean => {
	if (!listType) {
		logger.warn({
			message: 'No list type provided for vehicle list',
			scope: 'isCorrectVehicleListFetchingState',
		});
		return false;
	}

	const listTypeToFetchingStateMap = {
		auction: isSaleActive || hasAlwaysFetchFilter,
		buyItNow: true,
		shortlist: true,
	};

	return !!listTypeToFetchingStateMap[listType];
};

export default isCorrectVehicleListFetchingState;
