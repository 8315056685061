/* eslint-disable local-rules/function-naming */
import { logger } from 'Services/logger/logger';

const captureExceptionWithTeam = (error, teamName, tags, extra) => {
	logger.warn({
		context: {
			extra: extra ? { ...extra } : undefined,
			tags: { ...tags, team: teamName },
		},
		error: error instanceof Error ? error : new Error(error || 'There was an error'),
		message: error instanceof Error ? error.message : 'There was an error',
		scope: 'captureExceptionWithTeam',
	});
};

export default captureExceptionWithTeam;
