import type { ResolveMonetaryValuePartsFn } from './MonetaryValue.types';

export const defaultResolveMonetaryValueParts: ResolveMonetaryValuePartsFn = (value) => {
	if (!value) {
		return { base: '0', fraction: '00' };
	}

	const [base, fraction] = Number(value).toFixed(2).split('.');

	return { base, fraction };
};
