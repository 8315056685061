import type { InfiniteData, QueryFunctionContext } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { getVehicles } from 'Services/api/vehicles';
import { logger } from 'Services/logger/logger';
import type { SaleTimes } from 'Types/saleTimes';
import { LIST_TYPES } from 'Utilities/consts';
import { FetchVehicleListError } from 'Utilities/error/errors';

import type { UseVehicleListQuery } from '../useVehicleListQuery/useVehicleListQuery';

import type { AllPages, GetPaginatedListOfIdsParams, VehicleListFetcher } from './useFetchVehicleList.types';

interface PageParam {
	loadedCount: number;
	nextPage: number;
}

type VehicleListFetcherParams = QueryFunctionContext<[string, UseVehicleListQuery], PageParam> & {
	isUsingCachedData: boolean;
};

const FIELDS_TO_OMIT = ['timeId'];

export const getLoadedVehiclesCount = (allPages: AllPages = []): number =>
	allPages?.reduce((acc, page) => {
		const totalPerPage = page?.data?.vehicles?.length || 0;
		acc += totalPerPage;
		return acc;
	}, 0);

export const vehicleListFetcher = async ({
	isUsingCachedData,
	pageParam,
	queryKey,
	signal,
}: VehicleListFetcherParams): Promise<VehicleListFetcher> => {
	const [, query = {}] = queryKey;
	const currentPage = pageParam?.nextPage || 0;
	const loadedCount = pageParam?.loadedCount || 0;
	const isFirstPage = currentPage === 0;
	const shouldOmitFields = Boolean(!isUsingCachedData && isFirstPage);
	const fieldsToOmit = shouldOmitFields ? FIELDS_TO_OMIT : [];
	const data = await getVehicles({ loadedCount, page: currentPage, ...omit(query, fieldsToOmit) }, signal);
	return {
		data,
		nextPage: data?.hasMore ? currentPage + 1 : undefined,
	};
};

interface CheckPreviewOrAuctionDuringActiveProps {
	filterQuery: UseVehicleListQuery['filterQuery'];
	isSaleActive: SaleTimes['isSaleActive'];
}

const isUserOnAuctionDuringSaleHours = ({ filterQuery, isSaleActive }: CheckPreviewOrAuctionDuringActiveProps) => {
	const params = new URLSearchParams(filterQuery);
	const listType = params.get(VEHICLE_LIST_FILTER_NAME.LIST_TYPE);

	return isSaleActive && listType === LIST_TYPES.auction;
};

interface CheckVehiclesAreFetchedDuringSaleProps {
	appliedFilterCount: number;
	isSaleActive: SaleTimes['isSaleActive'];
	query: UseVehicleListQuery;
	vehicleCount?: number;
}

export const checkVehiclesAreFetchedDuringSale = ({
	appliedFilterCount,
	isSaleActive,
	query,
	vehicleCount = 0,
}: CheckVehiclesAreFetchedDuringSaleProps): void => {
	const hasVehicles = vehicleCount > 0;

	if (hasVehicles) {
		return;
	}

	const isAuctionDuringSaleHours = isUserOnAuctionDuringSaleHours({
		filterQuery: query.filterQuery,
		isSaleActive,
	});

	if (isAuctionDuringSaleHours && appliedFilterCount === 0) {
		logger.error({
			error: new FetchVehicleListError(`checkVehiclesAreFetchedDuringSale`),
			message: `No vehicles were fetched during sale hours`,
			scope: 'useFetchVehicleList helpers',
		});
	}
};

const getLatestVehicleListPage = (vehicleData: InfiniteData<VehicleListFetcher>) => {
	const lastIndex = (vehicleData?.pages?.length || 1) - 1;
	const lastPage = vehicleData?.pages?.[lastIndex];
	return { lastIndex, lastPage };
};

const getPaginatedListOfIds = ({ lastIndex, lastPage }: GetPaginatedListOfIdsParams) => {
	const vehicleIds = lastPage?.data?.vehicles?.map((vehicle) => vehicle.id);
	const pageNumber = lastIndex + 1;

	return `${pageNumber}: ${vehicleIds?.join(',')}`;
};

export const getLatestVehicleEnquiryIds = (vehicleData: InfiniteData<VehicleListFetcher>): string => {
	const { lastIndex, lastPage } = getLatestVehicleListPage(vehicleData);
	const hasVehicles = Boolean(lastPage?.data?.totalCount);

	if (!hasVehicles) {
		return '';
	}

	return getPaginatedListOfIds({ lastIndex, lastPage });
};
