import type { ReactElement } from 'react';
import React from 'react';
import type { NavigationMenuItemProps } from '@radix-ui/react-navigation-menu';
import { NavigationMenuItem } from '@radix-ui/react-navigation-menu';

const MenuList = ({ children, ...props }: NavigationMenuItemProps): ReactElement => (
	<NavigationMenuItem {...props}>{children}</NavigationMenuItem>
);

export default MenuList;
