import type { ReactNode, Ref } from 'react';
import React from 'react';
import { MDText } from 'i18n-react';

import { RoundButton } from 'Storybook/core/RoundButton';
import type { RoundButtonPropsBase } from 'Storybook/core/RoundButton/RoundButton.types';
import { Variant } from 'Types/variant';

import { parseInitials } from './Avatar.helper';
import LocalTexts from './Avatar.texts';

export type AvatarProps = Omit<RoundButtonPropsBase, 'variant'> & {
	fullName?: ReactNode;
	ref?: Ref<HTMLButtonElement>;
};

const LocalT = new MDText(LocalTexts);

const Avatar = React.forwardRef<HTMLButtonElement, AvatarProps>(
	({ cypressId = 'avatarButton', fullName, onClick, ...props }, forwardRef) => (
		<RoundButton
			ref={forwardRef}
			cypressId={cypressId}
			onClick={onClick}
			text={parseInitials(fullName)}
			variant={Variant.PRIMARY}
			{...props}
			aria-label={String(LocalT.translate('profileLabel'))}
		/>
	),
);

export default Avatar;
