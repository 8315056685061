import { useRouter } from 'next/router';

import { cleanUrlString } from 'Utilities/helpers/CleanUrlString/cleanUrlString';
import { useScrollData } from 'Utilities/hooks/useScrollData';
import ROUTES from 'Utilities/routes';

import type { PageNavigationState } from './SiteNavigation.types';

const {
	ACCOUNT,
	CONTACT_US,
	COOKIES,
	DEALER_WALLET,
	DELIVERY,
	INDEX,
	MANAGE_SAVED_SEARCH,
	MANAGE_SAVED_SEARCH_ADD,
	MANAGE_SAVED_SEARCH_CONFIRMATION,
	MANAGE_SAVED_SEARCH_DELETE,
	MODULR_PAYMENT_TERMS,
	PAYMENT_TERMS,
	PRICING,
	PRIVACY,
	SELLER_DETAILS,
	SIGN_IN,
	SIGN_UP,
	TERMS,
	VEHICLE_DETAILS,
	VEHICLE_LIST,
	WITHDRAW_FUNDS,
} = ROUTES;

type IdParsedFromQuery = string | string[] | undefined;

interface GetPageNavigationStateParams {
	enquiryId: IdParsedFromQuery;
	isTopOfPage: boolean;
	slug: IdParsedFromQuery;
	vehicleId: IdParsedFromQuery;
}

interface GetPageNavigationStateReturn {
	[key: string]: Pick<PageNavigationState, 'isSticky' | 'isTransparent' | 'hasBottomBorder'>;
}

const getPageNavigationState = ({
	enquiryId,
	isTopOfPage,
	slug,
	vehicleId,
}: GetPageNavigationStateParams): GetPageNavigationStateReturn => {
	const PAGE_NAVIGATION_STATE = {
		[ACCOUNT.as()]: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: false,
		},
		default: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: false,
		},
		[INDEX.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: isTopOfPage,
		},
		[SIGN_IN.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[SIGN_UP.as()]: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: true,
		},
		[PRICING.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[VEHICLE_LIST.as()]: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: true,
		},
		[VEHICLE_DETAILS.as(Number(vehicleId))]: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: false,
		},
		[DELIVERY.as(Number(enquiryId))]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[SELLER_DETAILS.as(Number(enquiryId))]: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: false,
		},
		[PAYMENT_TERMS.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[CONTACT_US.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[TERMS.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[PRIVACY.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[COOKIES.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[MODULR_PAYMENT_TERMS.as()]: {
			hasBottomBorder: false,
			isSticky: true,
			isTransparent: false,
		},
		[DEALER_WALLET.as(slug as string)]: {
			hasBottomBorder: true,
			isSticky: true,
			isTransparent: false,
		},
		[WITHDRAW_FUNDS.as(slug as string)]: {
			hasBottomBorder: false,
			isSticky: false,
			isTransparent: false,
		},
	};

	return PAGE_NAVIGATION_STATE;
};

const useNavigationState = (): PageNavigationState => {
	const {
		asPath,
		query: { enquiryId, slug, vehicleId },
	} = useRouter();
	const { isTopOfPage } = useScrollData();
	const asPathUrl = cleanUrlString(asPath);

	const PAGE_NAVIGATION_STATE = getPageNavigationState({ enquiryId, isTopOfPage, slug, vehicleId });

	const isHidden = (
		[
			MANAGE_SAVED_SEARCH.as(),
			MANAGE_SAVED_SEARCH_ADD.as(),
			MANAGE_SAVED_SEARCH_CONFIRMATION.as(),
			MANAGE_SAVED_SEARCH_DELETE.as(),
		] as string[]
	).includes(asPathUrl);

	return {
		...(PAGE_NAVIGATION_STATE[asPathUrl] || PAGE_NAVIGATION_STATE.default),
		isHidden,
	};
};

export { useNavigationState };
