import cx from 'classnames';

import type { BaseThemeVariants } from 'Storybook/config/types';

import styles from './Link.module.scss';

export const getItemClass = (
	variant: BaseThemeVariants,
	isActive: boolean,
	isListItem: boolean,
	isIconRight: boolean,
): string =>
	cx(styles.link, styles[variant], {
		[styles.isActive]: isActive,
		[styles.listItem]: isListItem,
		[styles.iconRight]: isIconRight,
	});
