import { ANALYTICS_CATEGORIES } from 'Services/analytics/analytics.const';

import { basePaymentsEvent } from './base/basePaymentEvent';
import type { PaymentEventProps } from './base/basePaymentEvent.type';

const {
	PAYMENTS: { PAYMENT_WALLET_WITHDRAW },
} = ANALYTICS_CATEGORIES;

export function paymentWalletWithdraw<CustomData extends UnknownShapeObject>(
	props: PaymentEventProps<CustomData>,
): void {
	basePaymentsEvent({
		category: PAYMENT_WALLET_WITHDRAW,
		...props,
	});
}
