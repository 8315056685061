import type { FC, HTMLProps, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import type { LinkProps } from 'next/link';
import Link from 'next/link';

import type { IconComponentProps } from '@motorway/mw-highway-code';

import type { ExtensionContent as ExtensionContentType } from 'Storybook/core/Menu/Menu.types';
import { applyCypressData } from 'Utilities/helpers';

import styles from './MenuButton.module.scss';

type MenuButtonProps = HTMLProps<HTMLButtonElement> &
	Partial<Pick<LinkProps, 'as' | 'href'>> & {
		cypressId?: string;
		extensionContent?: ExtensionContentType;
		icon: (props: IconComponentProps) => ReactElement;
		id?: string;
		isActivePage?: boolean;
		onClick?: () => void | Promise<void>;
		text: ReactNode;
	};

const MenuButton: FC<MenuButtonProps> = ({
	as,
	cypressId,
	extensionContent: ExtensionContent,
	href,
	icon: Icon,
	isActivePage = false,
	onClick,
	text,
}) => {
	const content = (
		<>
			<Icon size="small" />
			<span>{text}</span>
			{ExtensionContent && <ExtensionContent wrapperClassName={styles.extensionContentWrapper} />}
		</>
	);

	if (href) {
		return (
			<Link passHref as={as} href={href} onClick={onClick}>
				<a
					{...applyCypressData(cypressId)}
					aria-current={isActivePage}
					className={cx(styles.menuButton, styles.menuButtonLink)}
				>
					{content}
				</a>
			</Link>
		);
	}

	return (
		<button
			{...applyCypressData(cypressId)}
			aria-current={isActivePage}
			className={styles.menuButton}
			onClick={onClick}
		>
			{content}
		</button>
	);
};

export default MenuButton;
