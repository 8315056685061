import type { RefObject, TransitionEventHandler } from 'react';
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import * as Portal from '@radix-ui/react-portal';
import { noop } from 'lodash';

import { Theme } from '@motorway/motorway-component-library';

import type { BaseThemeVariants } from 'Storybook/config/types';
import MenuToggle from 'Storybook/peripheral/MenuToggle/MenuToggle';
import type { OnCloseEvent } from 'Types/drawer';
import { Variant } from 'Types/variant';
import { APP_THEME } from 'Utilities/consts';

import { FOCUSABLE_ELEMENTS } from './Drawer.const';
import { handleKeyboardPress, toggleOverflowHidden } from './Drawer.helpers';

import styles from './Drawer.module.scss';

type DivProps = React.HTMLProps<HTMLDivElement>;

export const DRAWER_BACKDROP_TEST_ID = 'drawer-backdrop';

export type DrawerProps = Omit<DivProps, 'size'> & {
	direction?: 'top' | 'right' | 'bottom' | 'left';
	fullHeightContent?: boolean;
	isOpen?: boolean;
	maxSize?: string;
	onClose: (event: OnCloseEvent) => void;
	onTransitionEnd?: TransitionEventHandler<HTMLDivElement>;
	size?: 'full' | 'large' | 'medium' | 'small' | 'fit-content';
	triggerRef?: RefObject<HTMLElement>;
	variant?: BaseThemeVariants;
};

const Drawer: React.FC<DrawerProps> = ({
	children,
	direction = 'left',
	fullHeightContent = false,
	isOpen = false,
	maxSize,
	onClose,
	onTransitionEnd = noop,
	size = 'medium',
	triggerRef,
	variant = Variant.PRIMARY,
	...props
}) => {
	const menuRef = useRef<HTMLDivElement>(null);
	const isYAxis = direction === 'bottom' || direction === 'top';
	const shouldRoundBorders = isYAxis && size !== 'full';

	const handleClose = (event: OnCloseEvent) => {
		toggleOverflowHidden(false);
		onClose(event);

		if (triggerRef?.current) {
			triggerRef.current.focus();
		}
	};

	// TODO: This is a cleanup function and please add back the curly braces
	// once the rule ESLint arrow-body-style disabled
	useEffect(() => () => toggleOverflowHidden(false), []);

	useEffect(() => {
		toggleOverflowHidden(isOpen);
		if (isOpen) {
			const cleanup = handleKeyboardPress(menuRef, handleClose);

			const firstElement = menuRef.current?.querySelector<HTMLElement>(FOCUSABLE_ELEMENTS);
			firstElement?.focus();

			return cleanup;
		}

		return undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleBackdropClick = (event: OnCloseEvent) => {
		if (event.target === event.currentTarget) {
			handleClose(event);
		}
	};

	const maxSizeStyles = isYAxis ? { maxHeight: maxSize } : { maxWidth: maxSize };
	return (
		<Portal.Root asChild>
			<Theme doNotAlterChildren theme={APP_THEME}>
				<div aria-hidden={!isOpen} {...props}>
					<div
						data-drawer-backdrop
						className={cx(styles.backdrop, { [styles.open]: isOpen })}
						data-testid={DRAWER_BACKDROP_TEST_ID}
						onClick={handleBackdropClick}
					/>
					<div
						ref={menuRef}
						aria-label="drawer"
						aria-modal="true"
						className={cx(styles.drawer, styles[direction], styles[size], styles[variant], {
							[styles.open]: isOpen,
							[styles.xAxis]: !isYAxis,
							[styles.yAxis]: isYAxis,
							[styles.roundedBorder]: shouldRoundBorders,
						})}
						onTransitionEnd={onTransitionEnd}
						role="dialog"
						style={maxSizeStyles}
					>
						<div data-drawer-top-bar className={styles.topBar}>
							<MenuToggle data-testid="drawer-close-button" isOpen={true} onClick={handleClose} variant={variant} />
						</div>
						<div
							data-drawer-content
							className={cx(styles.content, {
								[styles.contentFullHeight]: fullHeightContent,
							})}
						>
							{children}
						</div>
					</div>
				</div>
			</Theme>
		</Portal.Root>
	);
};

export default Drawer;
