import { useListType } from 'Stores/ListStore/ListStore';
import type { ListType } from 'Types/listType';
import type { Nullable } from 'Types/utils';
import { LIST_TYPES } from 'Utilities/consts';
import useSaleTimes from 'Utilities/hooks/useSaleTimes';

interface UseAdjustedListTypeProps {
	forceFormatBasedOnTime?: boolean;
}

export const useAdjustedListType = ({
	forceFormatBasedOnTime = false,
}: UseAdjustedListTypeProps = {}): Nullable<ListType> => {
	const listType = useListType();
	const { hasReceivedSaleTimes, isSaleActive } = useSaleTimes();
	const listTypeBasedOnSaleTime = isSaleActive ? LIST_TYPES.auction : LIST_TYPES.buyItNow;

	if (!hasReceivedSaleTimes) {
		return null;
	}

	if (forceFormatBasedOnTime) {
		return listTypeBasedOnSaleTime;
	}

	return listType || listTypeBasedOnSaleTime;
};
