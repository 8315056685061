import React from 'react';
import cx from 'classnames';
import type { AccordionItemProps } from '@radix-ui/react-accordion';
import { Item } from '@radix-ui/react-accordion';

import styles from './AccordionItem.module.scss';

const AccordionRoot = React.forwardRef<HTMLDivElement, AccordionItemProps>(
	({ children, className, ...props }, forwardedRef) => (
		<Item className={cx(styles.item, className)} {...props} ref={forwardedRef}>
			{children}
		</Item>
	),
);

export default AccordionRoot;
